import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { MdAdd } from 'react-icons/md'

import {
  breakpoints,
  colors,
  fonts,
  radius,
  spacing,
  animations
} from 'utils/styles'
import { convertToMoney } from 'utils/priceFormat'
import ProductForm from './ProductForm'

const ProductListingItem = props => {
  const {
    slider=false,
    sku,
    product: {
      id,
      metadata: {
        nick_name
      },
      name,
      slug,
      description,
      price,
      images,
      hasVariants,
      skus
    },
    hideCart=false
  } = props
  let formatPrice = skus[0].price
  let formatPriceHigh = 0
  if (hasVariants) {
    formatPrice = skus[skus.length-1].price
    formatPriceHigh = skus[0].price
  }
  return (
    <ProductListingItemLink to={`/item/${slug}`}>
      <Item>
        <Preview slider={slider}>
         {/* {images && <img src={images[0]} /> } */}
        </Preview>
        <Body>
        <Name>{nick_name ? nick_name : name}</Name>
        <SubName>{nick_name && name}</SubName>
          <Price>
            {convertToMoney(formatPrice)}{hasVariants&& ' - '+convertToMoney(formatPriceHigh)}
          </Price>
          </Body>
          {!hideCart && skus && <ProductForm id={slug} hasVariants={hasVariants} variants={skus} onClick={(e)=>e.stopPropagation()}/>}
      </Item>
    </ProductListingItemLink>
  )
}

const TRANSITION_DURATION = '250ms'

const ProductListingItemLink = styled(Link)`
  background: ${colors.lightest};
  border-radius: ${radius.large}px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  text-decoration: none;
  transition: all ${TRANSITION_DURATION};
`

const Item = styled(`article`)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  min-width: 250px;
`

const Preview = styled(`div`)`
  border-bottom: 1px solid ${colors.brandLight};
  margin-bottom: ${spacing.sm}px;
  position: relative;
  align-items: center;
  display:flex;
  justify-content:center;
  margin: ${props => (props.slider ? '5px' : '24px')};
  img {
    width: 100%;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: 220px;
    object-fit: cover;
  }
`

const Body = styled('div')`
  padding: 0 1.25rem;
`

const Name = styled(`h1`)`
  color: #222;
  font-family: ${fonts.heading};
  font-size: .9rem;
  line-height: 1.2;
  margin: 0;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
`

const SubName = styled(Name)`
  color: #adb5bd;
  font-family: ${fonts.heading};
  font-size: .7rem;
  font-weight:500
`

const Description = styled(`p`)`
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.5;
`

const PriceRow = styled(`div`)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const Price = styled(`div`)`
  color: #444;
  font-size: .9rem;
  font-weight: 500;
  letter-spacing: -0.02em;
  align-self:flex-end;
  margin-top: 5px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90px;
  span {
    color: ${colors.textLight};
  }
`

const Incentive = styled('div')`
  align-items: center;
  color: ${colors.lilac};
  display: flex;
  font-size: 0.9rem;
  line-height: 1.3;
  margin-bottom: ${spacing['2xs']}px;
  text-align: right;
  transition: all ${TRANSITION_DURATION};
`

const CartIcon = styled(`span`)`
  align-items: center;
  border: 1px solid #222;
  border-radius: 100px;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  transition: all ${TRANSITION_DURATION};
  vertical-align: middle;
  width: 40px;

  @media (hover: hover) {
    ${ProductListingItemLink}:hover & {
      margin-left: ${spacing.xs}px;
    }
  }

  svg {
    color: #222;
    height: 22px;
    position: relative;
    width: 22px;
  }
`

ProductListingItem.propTypes = {
  product: PropTypes.object.isRequired
}

export default ProductListingItem
