import React, { Component, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Fieldset, Input, Label, Select, Submit } from '../shared/FormElements'
import { breakpoints, colors, spacing, radius } from '../../utils/styles'
import useMediaQuery from 'utils/hooks/useMediaQuery'
import { CartContext } from 'context/CartProvider'
import Button from 'components/shared/Button'

const ProductForm = (props) => {
  const [variant, setVariants] = useState(props.variants[0].id)
  const [quantity, setQuantity] = useState(1)

  const handleChange = e => {
    e.stopPropagation()
    e.preventDefault()
    this.setQuantity(e.target.value)
  }

  const handleSubmit = callback => event => {
    event.preventDefault()
    callback(variant, quantity)
  }
  
  const { variants } = props
  const { add } = useContext(CartContext)

  if (props.hasVariants) {
    return (
      <Button accentInverse>View Options</Button>
    )
  }

  return (
    <Form onSubmit={handleSubmit(add)} noValidate onClick={(e)=>e.stopPropagation()}>
      <QtyFieldset>
        <Input
          type="number"
          id="quantity"
          name="quantity"
          min="1"
          step="1"
          onClick={(e)=>e.stopPropagation()}
          onChange={e=>handleChange(e)}
          value={quantity}
        />
      </QtyFieldset>
      <AddToCartButton
        type="submit"
        onClick={(e)=>e.stopPropagation()}
        fullWidth={false}
      >
        Add to Cart
      </AddToCartButton>
    </Form>
  )  
}

const Form = styled(`form`)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: ${breakpoints.desktop}px) {
    justify-content: flex-start;
  }
`

const QtyFieldset = styled(Fieldset)`
  width: 70px;
  ${Input} {
    padding: ${spacing.sm}px ${spacing.sm}px;
    text-align: center;
    border-radius:0px;
  }
`
const AddToCartButton = styled(Submit)`
  align-self: flex-end;
  height:53px;
  flex:1;
  border-radius:0;
  margin-top: 0;
  font-size:17px;
`

ProductForm.propTypes = {
  id: PropTypes.string.isRequired,
  variants: PropTypes.array.isRequired
};

export default ProductForm;